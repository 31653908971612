import React from "react";
import LeaderShipImg from "../../assets/img/leadership.png";
import Leader1 from "../../assets/img/leader1.png";
import Leader2 from "../../assets/img/leader2.png";
import Leader3 from "../../assets/img/leader3.png";
import Leader4 from "../../assets/img/leader4.png";
const LeaderShip = () => {
  const LeaderShipData = [
    {
      LeaderImg: Leader1,
      heading: "John Doe",
      leadertext: "Mauris pretium,USA ",
    },
    {
      LeaderImg: Leader2,
      heading: "John Doe",
      leadertext: "Mauris pretium,USA ",
    },
    {
      LeaderImg: Leader3,
      heading: "John Doe",
      leadertext: "Mauris pretium,USA ",
    },
    {
      LeaderImg: Leader4,
      heading: "John Doe",
      leadertext: "Mauris pretium,USA ",
    },
    {
      LeaderImg: Leader1,
      heading: "John Doe",
      leadertext: "Mauris pretium,USA ",
    },
    {
      LeaderImg: Leader2,
      heading: "John Doe",
      leadertext: "Mauris pretium,USA ",
    },
    {
      LeaderImg: Leader3,
      heading: "John Doe",
      leadertext: "Mauris pretium,USA ",
    },
    {
      LeaderImg: Leader4,
      heading: "John Doe",
      leadertext: "Mauris pretium,USA ",
    },
  ];
  return (
    <div>
      <div className="leadership-above-bgimage ">
        <img className="w-100 our-story-img" src={LeaderShipImg} alt="LeaderShipImg" />
      </div>
      {/* <div className="bg-light-orange py-lg-5">
        <div className="py-sm-3">
          <h1 className="main-heading text-center py-sm-4 py-3">
            Our leadership
          </h1>
          <div className="container">
            <div className="row">
              {LeaderShipData &&
                LeaderShipData.map((value, index) => {
                  return (
                    <div className="col-lg-3 col-6 py-sm-4 py-2" key={index}>
                      <img
                        className="d-block mx-auto mb-3 w-75 leadership-img"
                        src={value.LeaderImg}
                        alt="LeaderImg"
                      />
                      <h1 className="text-center featured-home-text font-weight-600">
                        {value.heading}
                      </h1>
                      <p className="featured-home-text text-center">
                        {value.leadertext}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default LeaderShip;
