import React from "react";
import {
  CommissionIcon,
  ManaEverythingIcon,
  PaymentIcon,
} from "../common/Icons";

const BussinessBoost = () => {
  return (
    <section className="bg-light-orange">
      <div className="container py-5">
        <div className="my-xl-5">
          <h1 className="text-center main-heading mb-sm-5 pb-5">
            How we boost your business
          </h1>

          <div className="row justify-content-lg-between justify-content-center">
            <div className="col-lg-4 col-md-6 col-10 mb-4 mb-sm-5">
              <div className="d-flex flex-column mx-3">
                <h2 className="featured-home-heading text-center d-flex align-items-center align-items-lg-end justify-content-center mb-0">
                  <span className="w-35 me-3">
                    <CommissionIcon />
                  </span>
                </h2>
                <p className="text-center agents-heading mb-0">
                  4% referral commission
                </p>
                <p className="mt-2 featured-home-text text-center px-xl-2">
                  You earn 4% referral commission, when you bring a buyer. If
                  you have multiple buyers interested in the same Mana – you get
                  paid a referral commission on the sale of each share
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-10 mb-4 mb-sm-5">
              <div className=" d-flex flex-column mx-3">
                <h2 className="m-0 featured-home-heading text-center d-flex align-items-center align-items-lg-end justify-content-center">
                  <span className="w-35 me-3 ">
                    <ManaEverythingIcon />
                  </span>
                </h2>
                <p className="text-center agents-heading mb-0">
                  {" "}
                  Mana handles everything
                </p>
                <p className="mt-2 featured-home-text text-center  px-xl-2">
                  Simply arrange the buyer introduction, and we take care of
                  everything else! From organising viewings and answering client
                  questions, to managing surveys, contract, conveyancing and
                  exchange, Mana does all the legwork
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-10 mb-4 mb-sm-5">
              <div className=" d-flex flex-column mx-3">
                <h2 className="m-0 featured-home-heading text-center d-flex align-items-center align-items-lg-end justify-content-center">
                  <span className="me-3">
                    <PaymentIcon />
                  </span>
                </h2>
                <p className="text-center agents-heading mb-0">Fast payment</p>
                <p className="mt-2 featured-home-text text-center px-xl-2">
                  Our limited company co-ownership structure means a share can
                  be sold in as little as one day. You get paid as soon as this
                  is completed
                </p>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button className="learn-btn px-5 py-3">REFER A BUYER</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BussinessBoost;
