import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ArrowIconFaq } from "../common/Icons";
import { faqsArray } from "../common/data";


const CommonQuestions = () => {
  const [view, setView] = useState(0);

  const activeHandler = (value) => {
    if (value === view) {
      setView("");
    } else {
      setView(value);
    }
  };

  return (
    <>
      <div className="bg-color-white py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-11 col-lg-10">
              <h1 className="main-heading text-center pb-5 mb-2">
                Common Questions
              </h1>

              {faqsArray &&
                faqsArray.map((item, index) => {
                  return (
                    <QuestionsListItem
                      key={index}
                      item={item}
                      id={index}
                      activeHandler={activeHandler}
                      view={view}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonQuestions;

export function QuestionsListItem({ item, id, activeHandler, view }) {
  return (
    <>
      <div
        onClick={() => activeHandler(id)}
        className={`faq-wrapper mb-4  ${id === view ? "faq-active" : ""}`}
      >
        <div
          className={`featured-home-heading mb-2 d-flex align-items-center justify-content-between h-100`}
        >
          {item.question}
          <span className={`${id === view ? "rotate-active" : ""}`}>
            <ArrowIconFaq />
          </span>
        </div>

        <AnimatePresence>
          {id === view ? (
            <>
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{
                  height: 0,
                  opacity: 0,
                }}
                className={`faq-answer`}
              >
                {item.answer}
              </motion.div>
            </>
          ) : (
            ""
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
