import React from "react";
import AgentSaleImg from "../../assets/img/agentsale.png";
const AgentsSales = () => {
  return (
    <div className="container">
      <div className="row py-5 my-xl-5">
        <div className="col-xl-6 ">
          <img className="w-100" src={AgentSaleImg} alt="OwnerShipImg" />
        </div>
        <div className="col-xl-6 my-auto">
          <div className=" px-xl-5 mx-xl-5 pt-xl-0 pt-5">
            <p className="slider-text text-sm-start text-center  ">
              AGENT INFORMATION
            </p>
            <h1 className="main-heading text-sm-start text-center">
              Close more deals
            </h1>
            <p className="slider-text text-sm-start text-center mb-0 me-sm-2 ">
              We help agents turn their clients’ holiday home dreams into
              reality. Our partner agents enjoy generous commission and new
              connections with buyers and sellers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentsSales;
