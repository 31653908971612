import { useEffect } from "react";
import DreamMana from "../component/ourstory/DreamMana";
import GotStarted from "../component/ourstory/GotStarted";
import LeaderShip from "../component/ourstory/LeaderShip";
import ManaHouse from "../component/ourstory/ManaHouse";
import OurstoryHero from "../component/ourstory/OurStoryHero";

const OurStory = () => {
  // IF USER REDIRECT TO LEARN PAGE THEN IT SCROLL TO TOP OF THE PAGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <OurstoryHero />
      <GotStarted />
      <DreamMana />
      <ManaHouse />
      <LeaderShip />
    </div>
  );
};

export default OurStory;
