import React from "react";
import ManaHome from "../../assets/img/homeimg.png";
const Pitch = () => {
  return (
    <div className="bg-light-orange">
      <div className="container">
        <div className="row pt-5">
          <div className="col">
            <h1 className="main-heading text-sm-start text-center">
              The Mana Pitch
            </h1>
            <p className="slider-text pe-xl-5 me-xl-5 text-sm-start text-center">
              Increase your earning power today and start working with Mana
              Homes.
            </p>
            <p className="slider-text pe-xl-5 me-xl-5 text-sm-start text-center ">
              Mana buys amazing luxury family homes in top locations and creates
              a Limited Company for each home. Buyers purchase the amount of
              ownership that meets their needs, starting at one-eighth. Each
              home has a maximum of eight owners, and when the home has been
              fully sold, Mana does not retain any ownership or shares. Mana
              does professionally manages the home. Handling home design,
              cleaning, maintenance, bill payment, repairs, taxes and more.
            </p>

            <p className="slider-text text-sm-start text-center">
              Owners use Manastay app, to book stays in their turnkey home
              anywhere from two days to two years in advance.
            </p>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-xl-6 order-xl-1 order-2 pt-xl-0 pt-4">
            <h1 className="agents-heading "> Why Mana Homes?</h1>
            <p className="slider-text ">
              • Opportunity to own a second home for dramatically less cost and
              management hassle than whole second home ownership.
            </p>
            <p className="slider-text pe-xl-4">
              • The capacity to buy a higher-tier second home
            </p>
            <p className="slider-text pe-xl-5">
              • Flexibility to own the amount of second home that’s right for
              them, ranging from 1/8 to 1/2.
            </p>
            <p className="slider-text pe-xl-5">
              {/* • The option to find and share a listing they would like to co-own
              — if it meets our criteria, we may partner with them to buy half!. */}
              • Find and share a listing customers would like to co-own — if it
              meets our criteria, we may partner with them to buy half!
            </p>
            <p className="slider-text pe-xl-5">
              • Effortless ownership of a turnkey second home,with Mana taking
              care of property managment, interior design, scheduling and bill
              payment.
            </p>
            <p className="slider-text pe-xl-5">
              • Easy resale. After one year of ownership, owners can set their
              own price and sell to an active marketplace of buyers, enjoying
              and equity gains.
            </p>
          </div>
          <div className="col-xl-6 order-xl-2 order-1">
            <img
              className="w-100 pitch-img border-radius-15"
              src={ManaHome}
              alt="ManaHome"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pitch;
