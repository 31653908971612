import React from "react";
import Video from "../../assets/mp4/Mana-Banner-Video.mov";
import { DownIcon } from "../common/Icons";

const Hero = () => {
  return (
    <>
      <section className="hero-section position-relative">
        <video
          className="h-100 w-100 object-cover"
          src={Video}
          loop
          muted
          playsInline
          autoPlay={true}
          width="100%"
        />
        <p className="position-absolute hero-text px-sm-3 px-2 text-center">
          A better way to buy and own a holiday home
        </p>
        <div className="view-btn">
          {" "}
          <button className="subscribe-btn py-3 px-4 ">VIEW LISTINGS</button>
        </div>
        <div className="down-arrow down-arrow-animation cursor-pointer">
          <a href="#second-section">
            {" "}
            <DownIcon />
          </a>
        </div>
      </section>
    </>
  );
};

export default Hero;
