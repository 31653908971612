// FAQ ARRAY DATA
export const faqsArray = [
  {
    question: "Do I really have ownership in a Mana Home?",
    answer: `Yes. Owning a Mana is true property ownership. You purchase ownership, starting at 1/8, in a property-specific limited company. The home is fully managed and designed specifically for co-ownership.`,
  },

  {
    question: "How do I schedule time to use my home?",
    answer: `You book time using the Mana app, powered by our Intelligient scheduling system. It’s easy to use and fair for all the owners, based on your ownership amount.`,
  },
  {
    question: "How does Mana co-ownership compare to a timeshare or whole home ownership?",
    answer: `Unlike a traditional timeshare involving a contractual right to use a property, owning a Mana is ownership of a property asset that moves in value with the local market. Scheduling is flexible, and the owner group is small. The purchase price and ongoing expenses are a fraction of the cost of whole home ownership, and you avoid the hassles of traditional ownership, like time spent doing property maintenance when you should be enjoying a well-earned break!`,
  },
  {
    question:
      "What monthly costs will I have to pay as a Mana owner?",
    answer: `Home operating costs, such as property management, maintenance, taxes and repairs, are passed directly to owners transparently with no markup. Costs are shared pro rata. Owners pay an additional monthly management fee which covers limited company oversight, ongoing owner support, and the technology that gives you the best ownership experience possible.`,
  },
  {
    question: "How are prospective owners evaluated?",
    answer: `
We vet all owners, who agree to a common-sense owner code of conduct. The home will be reserved for the exclusive use of owners and their guests.
`,
  },
  {
    question: "What if I want to sell my ownership interest in the home?",
    answer: `
After 12 months of ownership, you can sell at any time, and you set the sale price. We have an active network of interested buyers, and the home will also be listed on Mana and other websites, and promoted across our marketing channels.
`,
  },
 
];

// BENIFITS ARRAY DATA

export const benifitedata = [
  {
    benifits: "Real estate investment with potential increase in value",
    dots: ".",
  },
  {
    benifits: "Beautiful house at a fraction of the price",
    dots: ".",
  },
  {
    benifits: "Long-lasting second home",
    dots: ".",
  },
  {
    benifits: "Turnkey house with no renovation and furnishing to supervise",
    dots: ".",
  },
  {
    benifits: "Hassle-free enjoyment with no management to take care of",
    dots: ".",
  },
  {
    benifits: "Year-round access to the home whenever you want",
    dots: ".",
  },
];

export const sidebardataList = [
  {
    title: "DISCOVER",
    list: [
      { title: "Home", url: "/" },
      { title: "Learn", url: "/learn" },
      { title: "Listings", url: "/" },
    ],
  },
  {
    title: "MORE",
    list: [
      { title: "Favorites", url: "/" },
      { title: "Agents", url: "/agents" },
      { title: "Selling", url: "/" },
      { title: "Blog", url: "/" },
      { title: "Our Story", url: "/ourstory" },
      { title: "Careers", url: "/" },
      { title: "Press", url: "/" },
      { title: "Communities", url: "/" },
    ],
  },
  {
    title: "SUPPORT",
    list: [
      { title: "FAQs", url: "/" },
      { title: "Contact Us", url: "/" },
    ],
  },
];
