import React, { useState, useEffect } from "react";
import { useLayoutProvider } from "../../context/LayoutProvider";
import Sidebar from "./Sidebar";
import { motion, AnimatePresence } from "framer-motion";
import {
  ManaWhiteLogo,
  HeaderWhiteHeartIcon,
  HamburgerToggleIcon,
  ManaGreenLogo,
} from "./Icons";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";

const Header = () => {
  const { sidebarActive, setSidebarActive } = useLayoutProvider();
  const [scrollPosition, setScrollPosition] = useState(0);
  const history = useHistory();
  const pathName = window.location.pathname;

  // TO FIND SCROLL Y POSITION
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // THIS USEFFECT GIVE US POSITION OF SCROLL IN EVERY PIXELS WE SCROLL
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openSidebar = () => {
    setSidebarActive(!sidebarActive);
    document.body.classList.add("overflow-hidden");
  };
  const closeSidebar = () => {
    setSidebarActive(!sidebarActive);
    document.body.classList.remove("overflow-hidden");
  };

  const topScroll = (value) => {
    if (value === "learn") {
      history.push("/learn");
      window.scrollTo(0, 0);
    } else if (value === "story") {
      history.push("/ourstory");
      window.scrollTo(0, 0);
    }
  };

  // WHEN WE CLICK ON LOGO REDIRECT TO TOP ON HOME PAGE
  const backToHomePage = () => {
    if (pathName === "/") {
      window.scrollTo(0, 0);
    } else {
      history.push("/");
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {/* SHOW GREEN NAVBAR ON LEARN PAGE  */}
      <div
        className={`py-4  header   ${
          pathName === "/learn" ||
          pathName === "/agents" ||
          pathName === "/ourstory"
            ? "bg-opacity"
            : scrollPosition > 100
            ? `${scrollPosition > 600 ? "bg-opacity" : "backdrop-filter-blur"}`
            : ""
        }`}
      >
        <div className="container">
          <div className="d-flex  w-100 align-items-center justify-content-between">
            <div className="cursor-pointer" onClick={() => backToHomePage()}>
              {/* IF SCROLL VALUE GREATER THAN 600 THAN, SHOW WHITE LOGO ELSE GREEN & IN LEARN PAGE SHOW WHITE LOOG ONLY */}
              {scrollPosition > 600 ? (
                <ManaWhiteLogo />
              ) : pathName === "/learn" ||
                pathName === "/agents" ||
                pathName === "/ourstory" ? (
                <ManaWhiteLogo />
              ) : (
                <ManaGreenLogo />
              )}
            </div>
            <div className="list-items  d-flex ">
              <li
                className="mx-lg-4 mx-md-2 underline-after-hover d-none d-md-inline-block"
                onClick={() => topScroll("learn")}
              >
                Learn
              </li>
              <li className="mx-lg-4 mx-md-2 underline-after-hover d-none d-md-inline-block">
                Listings
              </li>
              <li
                className="mx-lg-4 mx-md-2 underline-after-hover d-none d-md-inline-block"
                onClick={() => topScroll("story")}
              >
                Our Story
              </li>

              <li className="mx-lg-4 mx-md-2 underline-after-hover d-none d-md-inline-block">
                Contact
              </li>
              <li className="mx-lg-4 mx-md-2 header-heart-icon-hover">
                <HeaderWhiteHeartIcon />
              </li>

              <li
                onClick={() => {
                  openSidebar();
                }}
                className="px-lg-4 px-md-1 px-3 cursor-pointer"
              >
                <HamburgerToggleIcon />
              </li>
            </div>
          </div>
        </div>
      </div>

      {/* SIDEBAR  */}
      <AnimatePresence>
        {sidebarActive && (
          <>
            <motion.div
              initial={{ x: 500, opacity: 0 }}
              animate={{
                x: 0,
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              }}
              exit={{
                x: 500,
                opacity: 0,
                transition: { duration: 0.5 },
              }}
              className="sidebar"
            >
              <Sidebar />
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {/* SHOW SIDEBAR OVERLAY WHEN SIDEBAR IS ACTIVE  */}
      {sidebarActive && (
        <div onClick={() => closeSidebar()} className="sidebar-overlay"></div>
      )}
    </>
  );
};

export default withRouter(Header);
