import React from "react";
import { Table } from "react-bootstrap";

const Benefits = () => {
  return (
    <div>
      <div className="row py-5">
        <div className="col-xl-8 pe-xl-5  order-xl-1 order-2 ">
          <Table
            responsive
            className="table-bg  table-borderless benifits-table"
          >
            <thead className="table-head">
              <tr className=" px-2">
                <th className="text-start py-3">THE BENEFITS</th>
                <th className="text-center white-space-xs-nowrap py-3">
                  Mana Home
                </th>
                <th className="text-center white-space-xs-nowrap py-3">
                  BUYING 100% OF A SECOND HOME
                </th>
                <th className="text-center white-space-xs-nowrap py-3">
                  Renting a holiday home
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="px-3">
                <td className="slider-text border-bottom table-text py-3">
                  Real estate investment with potential increase in value
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots "></span>
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots"></span>
                </td>
                <td className="text-center border-bottom  table-text py-3"></td>
              </tr>
              <tr className="px-3">
                <td className="slider-text border-bottom table-text py-3">
                  Beautiful house at a fraction of the price
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots "></span>
                </td>
                <td className="text-center border-bottom  table-text py-3"></td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots "></span>
                </td>
              </tr>
              <tr className="px-3">
                <td className="slider-text border-bottom table-text py-3">
                  Long-lasting second home
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots "></span>
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  {" "}
                  <span className="d-inline-block dots " />
                </td>
                <td className="text-center border-bottom  table-text py-3"></td>
              </tr>
              <tr className="px-3">
                <td className="slider-text border-bottom table-text py-3">
                  Turnkey house with no renovation and furnishing to supervise
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots "></span>
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  {" "}
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots " />
                </td>
              </tr>
              <tr className="px-3">
                <td className="slider-text border-bottom table-text py-3">
                  Hassle-free enjoyment with no management to take care of
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots "></span>
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  {" "}
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots " />
                </td>
              </tr>
              <tr className="px-3">
                <td className="slider-text border-bottom table-text py-3">
                  Year-round access to the home whenever you want
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots "></span>
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  {" "}
                </td>
                <td className="text-center border-bottom  table-text py-3">
                  <span className="d-inline-block dots " />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <div className="col-xl-4 my-xl-auto order-xl-2 order-1  mb-4">
          <p className="main-heading text-sm-start text-center">
            Treat yourself to a worry-free haven with Mana, The smartest way to
            own a second home.
          </p>
          <p className="slider-text text-sm-start text-center">
            Create unforgettable memories with your family and friends in your
            Mana home.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
