import React from "react";
import Ourstory from "../../assets/img/ourstory.png";
const OurstoryHero = () => {
  return (
    <div className="position-relative mt-4 pt-5">
      <img
        className="w-100 our-story-hero-img object-cover"
        src={Ourstory}
        alt="Ourstory"
      />

      <div className="position-absolute h-100 d-flex align-items-center justify-content-center top-0 our-story-hero-img w-100">
        <div className="container">
          <p className="main-heading text-white mb-0 text-center">
            Re-defining the second home market
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurstoryHero;
