import React, { useContext, useState } from "react";

const LayoutProviderContext = React.createContext();

export function useLayoutProvider() {
  return useContext(LayoutProviderContext);
}

export default function LayoutProvider({ children }) {
  const [sidebarActive, setSidebarActive] = useState(false);

  const value = { sidebarActive, setSidebarActive };
  return (
    <LayoutProviderContext.Provider value={value}>
      {children}
    </LayoutProviderContext.Provider>
  );
}
