import React from "react";
import propertyImg1 from "../../assets/img/property-image-1.jpeg";
import propertyImg2 from "../../assets/img/property-image-2.jpeg";
import propertyImg3center from "../../assets/img/property-image-3.jpeg";
import propertyImg4 from "../../assets/img/property-image-4.jpeg";
import propertyImg5 from "../../assets/img/property-image-5.jpeg";
import { HeartIcon } from "../common/Icons";

const FeaturedHome = () => {
  return (
    <div className="container-xl-fluid container ps-xl-0 py-5">
      <h2 className="main-heading text-center">Featured homes</h2>
      <p className="text-center featured-home-text px-sm-5 mx-sm-5">
        Discover our curated selection of beautiful homes in dream locations.
      </p>

      <div className="row pt-5">
        <div className="col-12 col-lg">
          <div className="d-flex flex-column flex-sm-row flex-lg-column">
            <div className=" my-md-2 features-cards">
              <div className="position-relative ">
                <img
                  className="w-100 h-sm-216"
                  src={propertyImg1}
                  alt="propertyImg3center"
                />
                <p className="image-text">4 BD, 3BA</p>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <p className="featured-home-heading mb-0">
                  £110,000 <span className="featured-home-text ">/ Share</span>
                </p>
                <span className="heart-icon-hover">
                  {" "}
                  <HeartIcon />
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <p className="featured-home-text">Newquay, Cornwall</p>
                <p className="featured-home-text">Available Now</p>
              </div>
            </div>
            <div className="ms-sm-3 ms-lg-0  my-md-2 features-cards">
              <div className="position-relative ">
                <img
                  className="w-100 h-sm-216"
                  src={propertyImg2}
                  alt="propertyImg3center"
                />
                <p className="image-text">5 BD, 3 BA</p>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <p className="featured-home-heading mb-0">
                  £125,000 <span className="featured-home-text ">/ Share</span>
                </p>
                <span className="heart-icon-hover">
                  {" "}
                  <HeartIcon />
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <p className="featured-home-text">Salcombe, Devon</p>
                <p className="featured-home-text">Available Now</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-5">
          <div className="d-flex flex-column justify-content-center px-lg-3  h-100  my-md-2 features-cards">
            <div className="position-relative h-100 ">
              <img
                className="w-100 property-center-img"
                src={propertyImg3center}
                alt="propertyImg3center"
              />
              <p className="image-text">5 BD, 4 BA</p>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className="featured-home-heading mb-0">
                £135,000 <span className="featured-home-text ">/ Share</span>
              </p>
              <span className="heart-icon-hover">
                {" "}
                <HeartIcon />
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <p className="featured-home-text">Newquay, Cornwall</p>
              <p className="featured-home-text">Available Now</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg">
          <div className="d-flex flex-column flex-sm-row flex-lg-column ">
            <div className=" my-md-2 features-cards">
              <div className="position-relative ">
                <img
                  className="w-100 h-sm-216"
                  src={propertyImg4}
                  alt="propertyImg3center"
                />
                <p className="image-text">4BD, 4 BA</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="featured-home-heading mb-0">
                  £185,000 <span className="featured-home-text ">/ Share</span>
                </p>
                <span className="heart-icon-hover">
                  {" "}
                  <HeartIcon />
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="featured-home-text">St Ives, Cornwall</p>
                <p className="featured-home-text">Available Now</p>
              </div>
            </div>
            <div className="ms-sm-3 ms-lg-0  my-md-2 features-cards">
              <div className="position-relative ">
                <img
                  className="w-100 h-sm-216"
                  src={propertyImg5}
                  alt="propertyImg3center"
                />
                <p className="image-text">4BD, 6 BA</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="featured-home-heading mb-0">
                  £220,000 <span className="featured-home-text ">/ Share</span>
                </p>
                <span className="heart-icon-hover">
                  {" "}
                  <HeartIcon />
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <p className="featured-home-text ">Salcombe, Devon</p>
                <p className="featured-home-text">Available Now</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 className="get-started-text text-center pt-5">VIEW ALL</h5>
    </div>
  );
};

export default FeaturedHome;
