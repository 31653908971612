import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/Home";
import Learn from "./pages/Learn";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./component/common/Header";
import Footer from "./component/common/Footer";
import LayoutProvider from "./context/LayoutProvider";
import Agents from "./pages/Agents";
import OurStory from "./pages/OurStory";

function App() {
  return (
    <>
      <Router>
        <LayoutProvider>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/learn" component={Learn} />
            <Route exact path="/agents" component={Agents} />
            <Route exact path="/ourstory" component={OurStory} />
          </Switch>
          <Footer />
        </LayoutProvider>
      </Router>
    </>
  );
}

export default App;
