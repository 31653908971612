import React from "react";
import BookingImg from "../../assets/img/booking-img.png";

const Booking = () => {
  return (
    <div className="container">
      <div className="row justify-content-between py-5 ">
        <div className="col-12 col-xl-5 ">
          <img
            className="w-100 d-block mx-auto"
            src={BookingImg}
            alt="BookingImg"
          />
        </div>
        <div className="col-12 col-xl-5 my-auto ">
          <div className=" pt-xl-0 pt-5">
            <h1 className="main-heading text-sm-start text-center">
              Easy and fair booking
            </h1>
            <p className="booking-small-text text-sm-start text-center">
              Reserve your stay through the Manastay app.
            </p>
            <p className="slider-text text-sm-start text-center max-w-xl-420">
              Owning a Mana Home gives you peace of mind that you can enjoy your
              home when it suits you best. With flexibility to book months in
              advance or on short notice.
            </p>
            <p className="slider-text text-sm-start text-center max-w-xl-507">
              Our Manastay algorithm confirms your booking and ensures a fair
              distribution of time for all co-owners.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
