import React from "react";
import { withRouter } from "react-router-dom";
import { EnjoyIcon, OwnIcon, ShopIcon } from "./Icons";

const Find = ({ isButton, history }) => {
  return (
    <section className="bg-light-orange" id="second-section">
      <div className="container py-5">
        <div className="my-xl-5">
          <h1 className="text-center main-heading m-0">
            Your dream second home with Mana
          </h1>
          <p className="text-center featured-home-text pb-4 pb-sm-5">
            We make it easy
          </p>
          <div className="row justify-content-lg-between justify-content-center">
            <div className="col-lg-4 col-md-6 col-10 mb-4 mb-sm-5">
              <div className="d-flex flex-column mx-3">
                <h2 className="featured-home-heading text-center d-flex align-items-center align-items-lg-end justify-content-center">
                  <span className=" me-3">
                    <ShopIcon />
                  </span>

                  <span>Shop</span>
                </h2>
                <p className="mt-2 featured-home-text text-center">
                  Explore a curated selection of luxury homes in dream
                  destinations. You decide what share of the property you would
                  like to own.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-10 mb-4 mb-sm-5">
              <div className=" d-flex flex-column mx-3">
                <h2 className="m-0 featured-home-heading text-center d-flex align-items-center align-items-lg-end justify-content-center">
                  <span className=" me-3 ">
                    <OwnIcon />
                  </span>
                  <span> Own</span>
                </h2>
                <p className="mt-2 featured-home-text text-center">
                  We create a limited company for each property and find
                  suitable owners while handling all the sale details. On
                  completion, co owners own 100% of the property.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-10 mb-4 mb-sm-5">
              <div className=" d-flex flex-column mx-3">
                <h2 className="m-0 featured-home-heading text-center d-flex align-items-center align-items-lg-end justify-content-center">
                  <span className=" me-3">
                    <EnjoyIcon />
                  </span>

                  <span>Enjoy</span>
                </h2>
                <p className="mt-2 featured-home-text text-center ">
                  Mana takes care of furnishings, fittings as well as all
                  maintenance and repairs. You just arrive to enjoy your perfect
                  holiday home.
                </p>
              </div>
            </div>
          </div>
          <div className="text-center">
            {isButton ? (
              <h5
                className="get-started-text"
                onClick={() => history.push("/learn")}
              >
                LEARN MORE
              </h5>
            ) : (
              <button className="learn-btn px-5 py-3">VIEW LISTINGS</button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withRouter(Find);
