import React from "react";
import learnheroimg from "../../assets/img/learnheroimg.png";

const ManaWorks = () => {
  return (
    <>
      <div className="pt-4 pt-sm-5">
        <p className="slider-heading text-center mb-0">HOW MANA WORKS</p>
        <h1 className="main-heading text-center ">
          We make co-ownership simple
        </h1>
        <div className="d-flex justify-content-center">
          <p className="slider-text text-center max-width-525 px-2">
            You own it, we manage it. It's the modern way to buy and own a
            holiday home
          </p>
        </div>
        {/* LEARN IMAGE */}
        <div className="pt-4 pt-sm-5  hero-img-height">
          <img
            className="w-100 h-100 object-cover"
            src={learnheroimg}
            alt="learnheroimg"
          />
        </div>
      </div>
    </>
  );
};

export default ManaWorks;
