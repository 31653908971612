import React from "react";
import googleplay from "../../assets/img/googleplay.png";
import appstore from "../../assets/img/appstore.png";
import {
  FooterLogo,
  InstagramIcon,
  LinkedIn,
  PinerestIcon,
  TwitterIcon,
  YouTubeIcon,
} from "./Icons";
import { withRouter, Link, useHistory } from "react-router-dom";

const Footer = () => {
  const history = useHistory();
  const pathName = window.location.pathname;

  const topScroll = (value) => {
    if (value === "learn") {
      history.push("/learn");
      window.scrollTo(0, 0);
    } else if (value === "story") {
      history.push("/ourstory");
      window.scrollTo(0, 0);
    }
  };
  // WHEN WE CLICK ON LOGO REDIRECT TO TOP ON AGENT
  const backToAgentPage = () => {
    if (pathName === "/agents") {
      window.scrollTo(0, 0);
    } else {
      history.push("/agents");
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className="footer-section pt-5 ">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-12">
            <span className="cursor-pointer" onClick={() => history.push("/")}>
              {" "}
              <FooterLogo />
            </span>
            <p className="footer-text mb-4">SIGN UP FOR FULL ACCESS</p>
            <p className="footer-text fw-normal mb-5">
              Unlock exclusive features & receive updates
            </p>
            <div className="position-relative footer-input-wrapper">
              <input type="email" placeholder="Email" />
              <button className="position-absolute footer-btn px-4 px-xl-5">
                UNLOCK
              </button>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt-4 mt-lg-0">
            <div className="row justify-content-between justify-content-sm-start py-lg-0 py-4">
              <div className="col-sm-4 col-auto  d-flex  justify-content-lg-end  justify-content-sm-start">
                <div>
                  <p className="footer-headings">OFFERINGS</p>
                  <p onClick={() => topScroll("learn")}>
                    <span className="footer-list">Learn</span>
                  </p>
                  <p>
                    <span className="footer-list">Listings</span>
                  </p>
                  <p>
                    <span className="footer-list">Scheduling</span>
                  </p>

                  <p>
                    <span className="footer-list">Buying</span>
                  </p>
                  <p>
                    <span className="footer-list">Selling</span>
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-auto  d-flex  justify-content-lg-end  justify-content-sm-start">
                <div>
                  <p className="footer-headings">ABOUT</p>
                  <p>
                    <span
                      className="footer-list"
                      onClick={() => topScroll("story")}
                    >
                      Our story
                    </span>
                  </p>
                  <p>
                    <span
                      className="footer-list"
                      onClick={() => backToAgentPage()}
                    >
                      Agents
                    </span>
                  </p>
                  <p>
                    <span className="footer-list">Careers</span>
                  </p>
                  <p>
                    <span className="footer-list">Press</span>
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-auto d-flex  justify-content-lg-end  justify-content-start">
                <div>
                  <p className="footer-headings">SUPPORT</p>

                  <p>
                    <span className="footer-list">FAQs</span>
                  </p>
                  <p>
                    <span className="footer-list">Privacy</span>
                  </p>
                  <p>
                    <span className="footer-list">Contact</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="border-top mt-0 mt-sm-5"></div>
        <div className="row align-items-center flex-xxl-row flex-column  py-xxl-4 py-3">
          <div className="col-4 d-flex justify-content-lg-start justify-content-center ">
            <span className="mx-sm-2 mx-1 d-inline-block cursor-pointer">
              {" "}
              <img
                className="footer-images"
                src={googleplay}
                alt="googleplay"
              />
            </span>
            <span className="mx-sm-2 mx-1 d-inline-block  cursor-pointer">
              <img className="footer-images" src={appstore} alt="appstore" />
            </span>
          </div>
          <div className="col-8 d-flex justify-content-lg-around justify-content-between mt-xxl-0 mt-4">
            <Link
              className="footer-icons"
              to="https://www.instagram.com/?hl=en"
            >
              {" "}
              <span>
                {" "}
                <InstagramIcon />
              </span>
            </Link>
            <Link className="footer-icons" to="https://in.pinterest.com/">
              <span>
                {" "}
                <PinerestIcon />
              </span>
            </Link>
            <Link className="footer-icons" to="https://www.linkedin.com/feed/">
              {" "}
              <span>
                {" "}
                <LinkedIn />
              </span>
            </Link>
            <Link className="footer-icons" to="https://twitter.com/home">
              {" "}
              <span>
                {" "}
                <TwitterIcon />
              </span>
            </Link>
            <Link className="footer-icons" to="https://www.youtube.com/">
              {" "}
              <span>
                {" "}
                <YouTubeIcon />
              </span>
            </Link>
          </div>
        </div>
        <div className="border-bottom"></div>
        <p className="text-center text-white mb-0 py-3">@copyrightmana2021</p>
      </div>
    </div>
  );
};

export default withRouter(Footer);
