import React from "react";
import { withRouter } from "react-router-dom";
import ownimg from "../../assets/img/own-thejoy-img.png";

const OwnJoy = ({ history }) => {
  return (
    <div className="ownjoy-section py-5 ">
      {/* OWN THE JOY  */}
      <div className="container py-5 my-xl-5">
        <div className="row flex-column-reverse flex-xl-row">
          <div className="col-xl-6 ">
            <img className="w-100 border-radius-15" src={ownimg} alt="ownimg" />
          </div>
          <div className="col-xl-6 ps-xl-5  pt-xl-0 pt-4  my-auto text-xl-start text-center ">
            <h2 className="main-heading">Enjoy your holiday home</h2>
            <p className="featured-home-text mx-auto mx-xl-0 max-w-xl-429">
              We're here to help you experience the joy of second home
              ownership. With Mana, you own a luxury second home for 1/8 of the
              cost, while avoiding the hassles of traditional ownership.
            </p>

            <p
              className="get-started-text pt-3"
              onClick={() => history.push("/learn")}
            >
              LEARN MORE
            </p>
          </div>
        </div>
      </div>

      {/* FORGET TIMESHARES  */}

      <div className="container py-3 py-sm-5 mt-xl-5">
        <div className="row flex-column flex-xl-row">
          <div className="col-xl-5 my-auto text-xl-start text-center ">
            <h2 className="main-heading">Mana is not timeshare</h2>
            <p className="featured-home-text  max-w-xl-429 mx-auto mx-xl-0">
              With Mana, you own a home, not just a block of time. You can book
              stays throughout the year, not annually. And resale? It's fast and
              streamlined, and you set the price.
            </p>

            <p
              className="get-started-text pt-3"
              onClick={() => history.push("/learn")}
            >
              LEARN MORE
            </p>
          </div>
          <div className="col-xl-7 mt-3 mt-sm-0">
            <div className="forget-timeshare-wrapper">
              <div className="d-flex">
                <div className="mana-home-div">
                  <p className="mb-0">MANA HOME</p>
                </div>
                <div className="resort-time-share-div">
                  <p className="mb-0">RESORT TIMESHARE</p>
                </div>
              </div>

              <div className="forget-table-list-item bg-white">
                <span>PROPERTY TYPE</span>
                <span>Single-family residence</span>
                <span>Hotel</span>
              </div>
              <div className="forget-table-list-item">
                <span>TRUE OWNERSHIP</span>
                <span>Yes, real property</span>
                <span> No, right-to-use time</span>
              </div>
              <div className="forget-table-list-item bg-white">
                <span>USAGE</span>
                <span>Ongoing access</span>
                <span>Fixed week(s)</span>
              </div>
              <div className="forget-table-list-item">
                <span>RESALE APPROACH</span>
                <span>Market pricing; sell on your terms</span>
                <span>Set pricing; sell with resort</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(OwnJoy);
