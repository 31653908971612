import React from "react";
import ManaHouseImg from "../../assets/img/manahouse.png";
const ManaHouse = () => {
  return (
    <div className="container my-lg-5  py-sm-5 py-4">
      <div className="row justify-content-between">
        <div className="col-lg-5 my-auto order-lg-1 order-2">
          <h1 className="main-heading text-sm-start text-center">
            Why Mana House
          </h1>
          <p className="featured-home-text  me-xl-5 text-sm-start text-center">
            We are focused on re-defining the second home market-creating
            properties that are high-end, accessible & hassle-free while solving
            the problem of supply & demand in key holiday destinations through
            co-ownership.
          </p>
        </div>
        <div className="col-lg-5 order-lg-2 order-1 ">
          <img
            className="w-100 border-radius-15 mb-lg-0 mb-4"
            src={ManaHouseImg}
            alt="ManaHouseImg"
          />
        </div>
      </div>
    </div>
  );
};

export default ManaHouse;
