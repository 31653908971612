import React from "react";
import AgentImg from "../../assets/img/agent.png";
const AgentsHero = () => {
  return (
    <div className="pt-5 ">
      <h1 className="main-heading text-center">The easiest sell of the year</h1>
      <div className="d-flex  justify-content-center">
        <p className="slider-text text-center max-width-525">
          We make it easy for you to earn generous commissions and delight
          clients.
        </p>
      </div>
      <div className="hero-img-height pt-sm-5 pt-4">
        <img
          className="w-100 h-100 object-cover"
          src={AgentImg}
          alt="agentimg"
        />
      </div>
    </div>
  );
};

export default AgentsHero;
