import React, { useEffect } from "react";
import Find from "../component/common/Find";
import CommonQuestions from "../component/common/CommonQuestions";
import Booking from "../component/learn/Booking";
import Costs from "../component/learn/Costs";
import Locations from "../component/learn/Locations";
import ManaWorks from "../component/learn/ManaWorks";
import Ownership from "../component/learn/Ownership";
import StressFree from "../component/learn/StressFree";

const Learn = () => {
  // IF USER REDIRECT TO LEARN PAGE THEN IT SCROLL TO TOP OF THE PAGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="pt-5 py-sm-5 mt-5 my-sm-5">
        <ManaWorks />
        <Find />
        <Ownership />
        <Locations />
        <Booking />
        <StressFree />
        <Costs />
        <CommonQuestions />
      </div>
    </>
  );
};

export default Learn;
