import React from "react";
import locationimg from "../../assets/img/manalocation.png";
const Locations = () => {
  return (
    <div className="ownjoy-section py-sm-5 ">
      {/* OWN THE JOY  */}
      <div className="container py-5 my-xl-5">
        <div className="row flex-column-reverse flex-xl-row">
          <div className="col-xl-5 pe-xl-5   pt-xl-0 pt-5  my-auto text-md-start text-center ">
            <h2 className="main-heading me-xl-5">
              Dream Homes in dream locations
            </h2>
            <p className="featured-home-text  mx-xl-0  max-w-xl-429">
              Mana offers luxury properties in some of the most sought-after
              holiday destinations in the UK and Europe. Found a dream home that
              is way over budget? Let Mana help you own a share and pay for only
              what you actually use
            </p>
          </div>
          <div className="col-xl-7 ">
            <img
              className="d-block mx-auto w-100 border-radius-15"
              src={locationimg}
              alt="locationimg"
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
