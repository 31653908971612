import React from "react";
import GotStartedImg from "../../assets/img/storystarted.png";
const GotStarted = () => {
  return (
    <div className="container my-sm-5 my-4 py-lg-5">
      <div className="row">
        <div className="col-xl-5 col-lg-6">
          <img
            className="w-100 border-radius-15 h-100 mb-xl-0 "
            src={GotStartedImg}
            alt="GotStartedImg"
          />
        </div>
        <div className="col-xl-7 col-lg-6 px-xl-5 my-auto">
          <h1 className="main-heading px-xl-5 pt-xl-0 pt-4 text-sm-start text-center">
            How we got started
          </h1>
          <p className="featured-home-text px-xl-5 mb-2 text-sm-start text-center">
            With 18 years of experience in interior & product design, founder
            Roxi Zeeman has travelled the globe relentlessly in the search for
            trends, organic materials & fair trade manufacturers.
          </p>
          <p className="featured-home-text px-xl-5 mb-2 text-sm-start text-center">
            She set up a network of over 3000 retailers based in the UK, Europe
            and the US dealing with iconic key accounts such as John Lewis,
            Selfridges, Galleries La Fayette & the Urban Group.
          </p>
          <p className="featured-home-text px-xl-5 mb-2 text-sm-start text-center">
            Throughout it, her number one passion has been to create an
            aspirational lifestyle through considered design, layers of texture
            & materiality.
          </p>
        </div>
      </div>
    </div>
  );
};

export default GotStarted;
