import React from "react";
import { useLayoutProvider } from "../../context/LayoutProvider";
import { CrossIcon } from "./Icons";
import { useHistory } from "react-router-dom";
import { sidebardataList } from "./data";

const Sidebar = () => {
  const { sidebarActive, setSidebarActive } = useLayoutProvider();
  const closeSidebar = () => {
    setSidebarActive(!sidebarActive);
    document.body.classList.remove("overflow-hidden");
  };
  const history = useHistory();
  const handleScroll = (value) => {
    history.push(value);
    window.scrollTo(0, 0);
    closeSidebar();
  };

  return (
    <>
      <div className="postion-relative pt-5 py-5 px-5 h-100 w-100">
        <p>SIGN UP / SIGN IN</p>
        <span className="cross-icon-sidebar" onClick={() => closeSidebar()}>
          <CrossIcon />
        </span>
        <div>
          {sidebardataList.map((item, index) => (
            <SidebarItems
              key={index}
              item={item}
              history={history}
              closeSidebar={closeSidebar}
              handleScroll={handleScroll}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;

export function SidebarItems({ item, handleScroll }) {
  return (
    <>
      <div className="sidebar-heading">{item.title}</div>
      <div className="mb-3 mt-1">
        {item.list.map((obj, index) => (
          <div
            className="sidebar-nav-link py-1"
            key={index}
            onClick={() => {
              handleScroll(`${obj.url}`);
            }}
          >
            <span className="d-inline-block underline-hover"> {obj.title}</span>
          </div>
        ))}
      </div>
    </>
  );
}
