import React from "react";

const DreamMana = () => {
  return (
    <div className="bg-light-orange py-lg-5 py-sm-4 py-3">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-9">
            <div className=" px-md-5 px-xxl-3 mx-xl-5 mx-xxl-1">
              <h1 className="featured-home-heading text-center py-lg-5 py-4 mb-0">
                “I have been adding value to properties for as long as I can remember and I am so excited to create an aspirational lifestyle experience for YOUR dream Mana”
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DreamMana;
