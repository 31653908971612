import React, { useEffect } from "react";
import FeaturedHome from "../component/home/FeaturedHome";
import Missout from "../component/home/Missout";
import OwnJoy from "../component/home/OwnJoy";
import Hero from "../component/home/Hero";
import Find from "../component/common/Find";

const Home = () => {
  // IF USER REDIRECT TO LEARN PAGE THEN IT SCROLL TO TOP OF THE PAGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Hero />
      <Find isButton={true} />
      <FeaturedHome />
      <OwnJoy />
      <Missout />
    </>
  );
};

export default Home;
