import React from "react";
import { MonthlyIcons, OneTimeIcons } from "../common/Icons";
import Benefits from "./BenefitsTable";

const Costs = () => {
  return (
    <div className="ownjoy-section">
      <div className="container py-xl-5">
        <div className="row py-5">
          <div className="col-xl-4 col-12 my-auto">
            <h1 className="main-heading text-sm-start text-center">Costs</h1>
            <p className="slider-text max-w-442 pb-xl-0 pb-4 text-sm-start text-center pe-xl-5 me-xl-5">
              There are no hidden surprises. All costs are transparent and
              shared amongst owners
            </p>
          </div>
          <div className="col-xl-8 col-12">
            <div className="row">
              <div className="col-md-6 ">
                <div className="cost-card p-3 h-100 mb-md-0 mb-3">
                  <span className="d-flex justify-content-md-start  justify-content-center">
                    {" "}
                    <MonthlyIcons />
                  </span>
                  <p className="cost-card-heading py-2 text-md-start text-center">
                    One -Time
                  </p>
                  <p className="cost-card-text pe-xl-3">
                    The cost of your shares (from 1 to 4 out of 8) including
                    pro-rata:
                  </p>
                  <ul>
                    <li className="cost-card-text ">
                      The real estate value of the property
                    </li>
                    <li className="cost-card-text">
                      Mana Services: renovation, furnishing, technology,
                      managment of co-owners, legal services,...
                    </li>
                    <li className="cost-card-text">Taxes</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="cost-card p-3 h-100 mt-md-0 mt-3">
                  <span className="d-flex justify-content-md-start  justify-content-center">
                    <OneTimeIcons />
                  </span>
                  <p className="cost-card-heading py-2 text-md-start text-center">
                    Monthly
                  </p>
                  <ul>
                    <li className="cost-card-text pe-xl-3">
                      House management fees (property management, maintenance,
                      taxes and repairs) at cost price and prorated by your
                      number of shares.
                    </li>
                    <li className="cost-card-text pe-xl-3 pt-2">
                      A Mana platform fee of £99 per share
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Benefits />
      </div>
    </div>
  );
};

export default Costs;
