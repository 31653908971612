import React, { useEffect } from "react";
import AgentsHero from "../component/agents/AgentsHero";
import AgentsSales from "../component/agents/AgentsSales";
import BussinessBoost from "../component/agents/BussinessBoost";
import Pitch from "../component/agents/Pitch";
import CommonQuestions from "../component/common/CommonQuestions";

const Agents = () => {
  // IF USER REDIRECT TO LEARN PAGE THEN IT SCROLL TO TOP OF THE PAGE
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="pt-5 py-sm-5 mt-5 my-sm-5">
      <AgentsHero />
      <BussinessBoost />
      <AgentsSales />
      <Pitch />
      <CommonQuestions />
    </div>
  );
};

export default Agents;
