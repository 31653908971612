import React from "react";

const Missout = () => {
  return (
    <div className="missout-section py-5 position-relative">
      <h2 className="main-heading text-center">Don't miss out</h2>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8 col-md-10 col-12">
            <p className="missout-text text-center">
              The best homes sell fast. See the latest listings, inspiring
              second homes and buying tips.
            </p>
            <div className="text-center py-5">
              <input
                type="email "
                className="border-top-0 w-100 missout-input border-end-0 border-start-0 bg-transparent"
                placeholder="ENTER EMAIL"
              />
            </div>
            <p className="missout-text pt-2 text-sm-start text-center">
              Select one of the following:
            </p>
            <div className="d-flex align-items-center flex-sm-row flex-column justify-content-between">
              <div>
                <input
                  type="radio"
                  id="html"
                  name="fav_language"
                  value="HTML"
                />
                 {" "}
                <label htmlFor="html" className="missout-text ">
                  I am a buyer
                </label>
              </div>
               
              <div>
                {" "}
                <input
                  type="radio"
                  id="css"
                  name="fav_language"
                  value="CSS"
                /> {" "}
                <label htmlFor="css" className="missout-text ">
                  I am a seller
                </label>
              </div>
               {" "}
              <div>
                <input
                  type="radio"
                  id="javascript"
                  name="fav_language"
                  value="JavaScript"
                />
                 {" "}
                <label htmlFor="javascript" className="missout-text ">
                  I'm an agent or broker
                </label>
              </div>
            </div>
            <div className="text-center my-5">
              <button className="subscribe-btn px-5 py-3">SUBSCRIBE</button>
            </div>
            <p className="text-center privacy-text">
              I give mana permission to contact me & agree to the
              <span className="higlight-text"> terms</span>.This site is
              protected by reCAPTCHA and the Google{" "}
              <span className="higlight-text">privacy policy</span> &{" "}
              <span className="higlight-text">terms of service.</span>
            </p>
            <p className="text-center contact-text">
              Want to chat?
              <span className="get-started-text px-1"> Contact us.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Missout;
