import React from "react";
import OwnerShipImg from "../../assets/img/owner.png";

const Ownership = () => {
  return (
    <div className="container">
      <div className="row py-5 my-xl-5">
        <div className="col-xl-6  ">
          <img
            className="w-100 border-radius-15 h-100 "
            src={OwnerShipImg}
            alt="OwnerShipImg"
          />
        </div>
        <div className="col-xl-6 my-auto">
          <div className=" px-xl-5 mx-xl-5 pt-xl-0 pt-5">
            <h1 className="main-heading text-sm-start text-center">
              Making Co Ownership work
            </h1>
            <p className="slider-text text-sm-start text-center mb-0 me-sm-4 ">
              We’ve modernised and simplified the process of co-ownership. Our
              limited company structure gives second home owners a streamlined
              way to own part of a luxury property, without the hassles of
              owning and maintaining an entire home. Owning through a limited
              company offers liability and financial protections, as well as the
              peace of mind of having Mana as the company manager.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ownership;
