import React from "react";
import StressFreeImg from "../../assets/img/holiday.png";
const StressFree = () => {
  return (
    <div className="container">
      <div className="row py-xl-5 pb-5">
        <div className="col-xl-5 my-auto order-xl-1 order-2">
          <div className="max-w-xl-429 ">
            <h1 className="main-heading text-sm-start text-center">
              Enjoy a stress-free holiday home
            </h1>
            <p className="slider-text text-sm-start text-center">
              With Mana Homes, you just sit back, relax and enjoy your home. We
              manage all aspects of your home while giving you full control and
              ownership.
            </p>
            <p className="slider-text text-sm-start text-center">
              We take care of furnishings, repairs and any planned or unforeseen
              maintenance. Your Mana home will always be clean and serviced to
              the highest standard.
            </p>
          </div>
        </div>
        <div className="col-xl-7 order-xl-2 order-1 ">
          <img
            className="w-100 mb-xl-0 mb-4 border-radius-15"
            src={StressFreeImg}
            alt="StressFreeImg"
          />
        </div>
      </div>
    </div>
  );
};

export default StressFree;
